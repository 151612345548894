@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: "Open Sans", sans-serif;
}

.font-link {
    font-family: 'Open Sans', sans-serif;
  }

.people-results-pagination{
    display: flex;
    justify-content: end;
}

/* 
details {
    width: 50%;
    margin: 0 auto;
    background: white;
    margin-bottom: .5rem;
    box-shadow: 0 .1rem 1rem -.5rem rgba(0, 0, 0, .4);
    border-radius: 5px;
    overflow: hidden;
}

summary {
    padding: 1rem;
    display: block;
    background: white;
    color: black;
    padding-left: 2.2rem;
    position: relative;
    font-weight: 00;
    cursor: pointer;
}

summary:before {
    content: '';
    border-width: .4rem;
    border-style: solid;
    border-color: transparent transparent transparent #fff;
    position: absolute;
    top: 1.3rem;
    left: 1rem;
    transform: rotate(0);
    transform-origin: .2rem 50%;
    transition: .25s transform ease;
}

 THE MAGIC 🧙‍♀️ 
details[open]>summary:before {
    transform: rotate(90deg);
}


details summary::-webkit-details-marker {
    display: none;
}

details>div {
    padding-left: 20px;
} 
*/

@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    overscroll-behavior-x: none;
}