/* Hide scrollbar */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
  .scrollbar-hide {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }


.active{
    background-color: black;
    color: white;
 }

 /* Tabs Candidates */

 /* CSS for Loading Spinner */
.loader {
    border: 4px solid #f3f3f3; /* Light gray */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  /* Spinner Animation */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  
/*Result*/

.myControlClassName{
    border-radius: 3px;
    background-color: #f5f8fa !important;
    border: 1px solid #cbd6e2;
}

.Dropdown-control{
    background-color: #f5f8fa !important;
}

.keywords-result div{
    margin: 10px 0;
    font-size: 16px;
}

.keywords-result div input{
    margin: 0 2px 0 0;
}

.checkbox-result div{
    margin: 10px 0;
    font-size: 16px;
}

.checkbox-result div input{
    margin: 0 2px 0 0;
    border-radius: 9999px !important;
}




.input-result{
    border-radius: 3px;
    background-color: #f5f8fa;
    border: 1px solid #cbd6e2;
}



.custom-shadow{
    box-shadow: 1px 6px 21px -7px rgba(0,0,0,0.75);
-webkit-box-shadow: 1px 6px 21px -7px rgba(0,0,0,0.75);
-moz-box-shadow: 1px 6px 21px -7px rgba(0,0,0,0.75);
}
/*People Result*/
.PeopleResults-container{
    padding: 0 !important;
}

.PeopleResults{
    padding: 10px !important;
}

.PeopleResults tr{
    text-align: left !important;
    padding: 5px 15px !important;
    border-bottom: 1px solid lightgray;
}

.PeopleResults tr th{
    text-align: left !important;
    padding: 5px 25px !important;
    height: 75px;
}

.PeopleResults tr td{
    text-align: left !important;
    padding: 5px 25px !important;
    height: 75px;
}

.mopadding{
    padding: 0 !important;
}

@media only screen and (max-width: 1499px) {
    .resultsform{
        padding: 0 0 0 95px;
    }
}


@media only screen and (max-width: 1025px) {
    .resultsform{
        padding: 0 0 0 100px;
    }
}

.textarea-sm{
    font-size: 13px !important;
}

.pdf-textarea-holder-1 .pdf-textarea-holder{
    width: 100%;
    height: 100% !important;
    position: relative;
    overflow: hidden;
}

.pdf-textarea-holder-1 .textarea-holder{
    height: 100%;
    width: 80%;
    float: right;
    transition: height 0.5s ease, width 0.5s ease;
}

.pdf-textarea-holder-1 .pdf-holder{
    width: 20%;
    height: auto;
    float: left;
    margin-left: -100px;
    filter: blur(1px);
    overflow: hidden;
    transition: height 0.5s ease, width 0.5s ease, margin-left 0.5s ease;
}

.rpv-core__inner-pages{
    overflow: hidden !important;
}


.pdf-textarea-holder-2 .pdf-textarea-holder{
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.pdf-textarea-holder-2 .textarea-holder{
    height: 100%;
    width: 50%;
    float: right;
    transition: height 0.5s ease, width 0.5s ease;
}

.pdf-textarea-holder-2 .pdf-holder{
    width: 50%;
    height: auto;
    float: left;
    transition: height 0.5s ease, width 0.5s ease, margin-left 0.5s ease;
}


.textarea-holder textarea {
    min-height: 700px;
  }

.compass_ipad{
    background: url(../../assets/images/Compass_Ipad.png) no-repeat;
    width: 210px;
    height: 135px;
    background-size: cover;
}

.compass_laptop{
    background: url(../../assets/images/Compass_Laptop.png) no-repeat;
    width: 210px;
    height: 135px;
    background-size: cover;
}

.compass_iphone{
    background: url(../../assets/images/compass_phone.png) no-repeat;
    width: 210px;
    height: 135px;
    background-size: cover;
}

.transformation{
    transition: height 2s ease;
}


.closed-amin {
    height: auto;
    transition: transform 0.26s ease;
  }
  
.ck-content {
    min-height: 500px !important;
}

.css-13cymwt-control{
    border-radius: 3px !important;
    background-color: #f5f8fa !important;
    border: 1px solid #cbd6e2 !important;
}

.css-1nmdiq5-menu{
    border-radius: 3px !important;
    background-color: #f5f8fa !important;
    border: 1px solid #cbd6e2 !important;
}

.ck-dropdown__button{
    z-index: 0 !important;
}

.buttonlookalike{
    display: block;
    width: 100%;
    padding: 4px 10px;
    font-weight:bold;
    border-radius: 5px;
}

.GdprGreen {
    border:2px solid #349e0f;
    color: #349e0f;
}

.GdprYellow {
    border:2px solid #ddcf0d;
    color: #ddcf0d;
}

.GdprRed {
    border:2px solid #ef2828;
    color: #ef2828;
}

.appendix{
    display: none !important;
}

.pdf-container{
    height: 835px !important;
    overflow: hidden;
    overflow-y:auto;
    border:1px solid rgb(197, 197, 197)
}

.rpv-default-layout__container{
    border: none !important;
}

.pdf-container::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.pdf-container::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

.pdf-container::-webkit-scrollbar-thumb
{
	background-color: #0ae;
	background-image: -webkit-gradient(linear, 0 0, 0 100%, color-stop(.5, rgba(255, 255, 255, .2)), color-stop(.5, transparent), to(transparent)) !important;
}

.pg-viewer-wrapper::-webkit-scrollbar-thumb
{
	background-color: #0ae;
	background-image: -webkit-gradient(linear, 0 0, 0 100%, color-stop(.5, rgba(255, 255, 255, .2)), color-stop(.5, transparent), to(transparent)) !important;
}

.pg-viewer-wrapper:-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.pg-viewer-wrapper::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}



.pdf-arrow{
    padding: 5px 18px 8px 14px;
    background: #1eade1;
    color: white;
    border-radius: 30px;
    top: 45% !important;
    font-weight: bold;
    z-index: 100 !important;
}

.button-custom{
    padding: 10px !important;
}

.defaulttextfield{
    height: 100% !important;
    width: 100% !important;
    border:2px solid gainsboro;
    border-radius: none;
    padding: 10px;
    margin: 0 0 10px 0;
}

.defaulttextfield::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.defaulttextfield::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

.defaulttextfield::-webkit-scrollbar-thumb
{
	background-color: #0ae;
	background-image: -webkit-gradient(linear, 0 0, 0 100%, color-stop(.5, rgba(255, 255, 255, .2)), color-stop(.5, transparent), to(transparent)) !important;
}

.defaulttextfield::-webkit-scrollbar-thumb
{
	background-color: #0ae;
	background-image: -webkit-gradient(linear, 0 0, 0 100%, color-stop(.5, rgba(255, 255, 255, .2)), color-stop(.5, transparent), to(transparent)) !important;
}

.defaulttextfield:-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.defaulttextfield::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

.rpv-default-layout__body{
    z-index: 0 !important;
}

.input-wrapper {
    position: relative;
}
  
.input-wrapper::after {
    content: '%';
    position: absolute;
    top: 50%;
    right: 10px; /* Adjust the value as needed for proper alignment */
    transform: translateY(-50%);
    pointer-events: none; /* Prevents the symbol from being selectable or clickable */
}

.input-wrapper input {
    padding-right: 20px; /* Adjust the value to accommodate the width of the symbol */
}

.file-viewer-style{
width: 100% !important;
}

.pg-viewer-wrapper .document-container {
width: 100% !important;
}

.short-bonus-input-wrapper {
    position: relative;
    margin-right: 10px;
}

.short-bonus-input-wrapper::after {
    content: attr(data-after);
    position: absolute;
    top: 50%;
    right: 10px; /* Adjust the value as needed for proper alignment */
    transform: translateY(-50%);
    pointer-events: none; /* Prevents the symbol from being selectable or clickable */
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  @media screen and (max-width: 1000px){

    .switch {
        position: relative;
        display: inline-block;
        width: 85px;
        height: 34px;
      }

    /* The switch - the box around the slider */
  
    input:checked + .slider:before {
        -webkit-transform: translateX(18px);
        -ms-transform: translateX(18px);
        transform: translateX(18px);
    }
    
  }

  @media screen and (max-width: 1500px){

    /* The switch - the box around the slider */
  
  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
    
  }

  @media screen and (min-width: 1050px){

    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
      }

    /* The switch - the box around the slider */
  
    input:checked + .slider:before {
        -webkit-transform: translateX(25px);
        -ms-transform: translateX(25px);
        transform: translateX(25px);
    }
    
  }

 